<template>
  <b-tabs type="is-toggle" v-model="activeTab" @input="changeTab">
    <b-tab-item
      v-for="tab in filteredTabs"
      :key="tab.key"
      :value="tab.key"
      :label="tab.name"
    ></b-tab-item>
  </b-tabs>
</template>
<script>
import { mapState } from 'vuex'
export default {
  data() {
    return {
      activeTab: 'overview',
    }
  },
  computed: {
    ...mapState('addyPlusBase', ['permissions']),
    tabs() {
      const { accountId, corporationId, propertyId } = this.$route.params
      return [
        {
          key: 'overview',
          name: 'Overview',
          path: `/addyPlus/${accountId}/corporations/${corporationId}/properties/${propertyId}`,
          permission: 'asset',
        },
        {
          key: 'updates',
          name: 'Updates',
          path: `/addyPlus/${accountId}/corporations/${corporationId}/properties/${propertyId}/updates`,
          permission: 'assetUpdates',
        },
        {
          key: 'dueDiligence',
          name: 'Due Diligence',
          path: `/addyPlus/${accountId}/corporations/${corporationId}/properties/${propertyId}/dueDiligence`,
          permission: 'assetDueDiligence',
        },
      ]
    },
    filteredTabs() {
      return this.tabs.filter((tab) => {
        return (tab.permission ? !!this.permissions?.[tab.permission]?.includes('read') : false)
      })
    },
  },
  created() {
    this.setActiveTab()
  },
  methods: {
    setActiveTab() {
      const path = this.$route.path
      const propertyId = this.$route.params.propertyId
      const route = path.split(propertyId)[1]
      if (route) {
        const firstSegment = route.split('/')[1]
        this.activeTab = firstSegment
      }
    },
    changeTab(key) {
      this.$router.push(this.tabs.find((tab) => tab.key === key).path)
    }
  }
}
</script>
<style lang="scss" scoped>
.b-tabs ::v-deep .tabs.is-toggle li.is-active a span {
  color: #fff;
}
</style>
